import './App.css';
import Dashboard from "./Dashboard";
import Card from "./Card";
import Button from "./Button";
// import Form from "./Form";

import { useState } from "react";

function App() {
  const [data, setData] = useState([{
    title: "Untitled dashboard",
    cards: []
  }]);

  function handleAddDashboard() {
    setData([...data, {
      title: "Untitled dashboard",
      cards: []
    }
    ]);
  }

  function handleAddCard(event) {

    let newData = [...data];

    newData[event.target.parentNode.id].cards.push({
      title: "Card",
      description: "Description"
    });

    setData(newData);
  }

  return (
    <div className="container">
      <h1>Your ToDo's</h1>
      <div className="todoApp">
        {data.map((item, index) =>
          <Dashboard title={item.title} id={index}>
            {item.cards.map((cardItem, index) =>
              <Card data={cardItem} key={"card" + index} />
            )}
            {<Button className="cardAdd" onClick={handleAddCard}>+</Button>}
          </Dashboard>
        )}
        <Button className="dashboardAdd" onClick={handleAddDashboard}>+</Button>
      </div>
    </div>
  );
};

export default App;
