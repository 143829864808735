import React from "react";

function Dashboard(props) {
  const {title, children, ...any} = props;

    return (
     <div className="dashboard-container" {...any}>
      <h1 contentEditable className="dashboard-name">{title}</h1>        
      {children}
    </div>
  );
}

export default Dashboard;