import React from "react";

function Card(props) {
	const {data, ...any} = props;
		
	function deleteCard(e) {
			e.target.parentElement.remove();
	}
 
  return (
    <div className="card-container" {...any}>
      <h2 contentEditable className="card-name">{data.title}</h2>
      <div contentEditable className="card-description">{data.description}</div>
			<button className="card-del-button" onClick={deleteCard}>x</button>
    </div>
  );
}

export default Card;
